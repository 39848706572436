import { appConfiguration } from './appConfiguration';
import { assignLicenseModal } from './assignLicenseModal';
import { banner } from './banner';
import { checkout } from './checkout';
import { cloudField } from './cloudField';
import { devices } from './devices';
import { deviceSettings } from './deviceSettings';
import { deviceSetup } from './deviceSetup';
import { general } from './general';
import { globalModals } from './globalModals';
import { license } from './license';
import { login } from './login';
import { notification } from './notification';
import { oAuth } from './oAuth';
import { organization } from './organization';
import { organizationBilling } from './organizationBilling';
import { organizationCalendarConnection } from './organizationCalendarConnection';
import { organizationExportDeviceData } from './organizationExportDeviceData';
import { organizationSettings } from './organizationSettings';
import { organizationSubscription } from './organizationSubscription';
import { organizationUsers } from './organizationUsers';
import { profile } from './profile';
import { resetPassword } from './resetPassword';
import { roomOverview } from './roomOverview';
import { scheduledContent } from './scheduledContent';
import { shared } from './shared';
import { sideNav } from './sideNav';
import { signage } from './signage';
import { signup } from './signup';

export const testIds = {
  appConfiguration,
  assignLicenseModal,
  banner,
  checkout,
  cloudField,
  devices,
  deviceSettings,
  deviceSetup,
  general,
  globalModals,
  license,
  login,
  notification,
  oAuth,
  organizationUsers,
  organizationSettings,
  organizationExportDeviceData,
  organizationCalendarConnection,
  organizationBilling,
  organizationSubscription,
  organization,
  profile,
  resetPassword,
  roomOverview,
  sideNav,
  signup,
  signage,
  shared,
  scheduledContent,
};
