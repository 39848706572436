import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { setShowSideNav } from 'src/components/routes/root/MainLayout/mainLayoutStore';

export type PublicRouteProps = {
  component: React.ComponentType<unknown>;
} & RouteProps;

/**
 * Public route can be accessed by both authenticated and not authenticated users.
 */
export const PublicRoute = ({
  component: Component,
  ...rest
}: PublicRouteProps): React.ReactElement => {
  useEffect(() => {
    setShowSideNav(false);
  }, []);

  return <Route {...rest} render={props => <Component {...props} />} />;
};
