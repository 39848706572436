import { logoutUser } from 'src/utils/auth';
import { APIError } from 'src/utils/error';
import { FetchBaseOptions } from './types';
import { getAuthApiErrorData, getCloudApiErrorData, getFetchOptions, isAuthUrl } from './utils';

export async function fetchBase(
  url: string,
  options?: FetchBaseOptions,
  logoutOn401 = true
): Promise<Response> {
  const response = await fetch(url, getFetchOptions(options || {}));
  const { status } = response;

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
  // Successful responses (200–299)
  if (status >= 200 && status <= 299) {
    return response;
  }

  // In the case of a 401, the user needs to log in again
  if (status === 401 && logoutOn401) {
    logoutUser();
  }

  const errorText = await response.text();

  const { message, data } = getErrorData(url, status, errorText);
  throw new APIError(status, message, data);
}

function getErrorData(
  url: string,
  status: number,
  errorText: string
): ReturnType<typeof getAuthApiErrorData> | ReturnType<typeof getCloudApiErrorData> {
  if (isAuthUrl(url)) {
    return getAuthApiErrorData(status, errorText);
  } else {
    return getCloudApiErrorData(errorText);
  }
}
