import React from 'react';
import classnames from 'classnames';
import { block } from './constants';

type LayoutHeaderProps = React.HTMLAttributes<HTMLHeadElement>;

export const LayoutHeader = ({
  children,
  className,
  ...props
}: LayoutHeaderProps): React.ReactElement => {
  const wrapperClassName = classnames(className, `${block}__header`);

  return (
    <header className={wrapperClassName} {...props}>
      {children}
    </header>
  );
};
