import {
  FreeSubscriptionStatus,
  PristineSubscriptionStatus,
  SubscriptionStatusType,
} from 'src/typings/Subscription';

export const trialDurationDays = 30;

export const expirationWarningDays = 7;

export const pristineSubscription: PristineSubscriptionStatus = {
  statusType: SubscriptionStatusType.PRISTINE,
};

export const defaultFreeSubscription: FreeSubscriptionStatus = {
  statusType: SubscriptionStatusType.FREE,
  wasTrial: false,
  expirationDate: '1970-01-01', // see note below
};
