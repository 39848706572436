import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { useNotification } from 'src/hooks/useNotification';
import { postLogoutUserService } from 'src/services/user/postLogoutUserService';
import { resetAuthStore } from 'src/store/authStore/authStore';
import { APIError, getErrorMessage } from 'src/utils/error';
import { AnalyticsEvents, googleAnalytics } from 'src/utils/googleAnalytics';

type PostLogoutUserMutationProps = {
  options?: UseMutationOptions<void, APIError, void>;
};

export function useDeleteUserSession(
  props: PostLogoutUserMutationProps = {}
): UseMutationResult<void, APIError, void> {
  const { errorNotification } = useNotification();
  const { onSuccess, onError, ...otherOptions } = props?.options || {};

  return useMutation({
    mutationFn: async () => postLogoutUserService(),
    onSuccess: (...context) => {
      resetAuthStore();

      googleAnalytics.track(AnalyticsEvents.LOGOUT);
      googleAnalytics.identify('');

      onSuccess?.(...context);
    },
    onError: (err, variables, context) => {
      errorNotification(getErrorMessage(err));
      onError?.(err, variables, context);
    },
    ...otherOptions,
  });
}
