import differenceInDays from 'date-fns/differenceInDays';
import {
  PaidSubscriptionStatus,
  SubscriptionCurrentStatus,
  SubscriptionInfo,
  SubscriptionInfoData,
  SubscriptionProductFullName,
  SubscriptionProductName,
  SubscriptionProductShortName,
  SubscriptionStatus,
  SubscriptionStatusType,
  Trial,
} from 'src/typings/Subscription';
import {
  SubscriptionApiModel,
  SubscriptionCurrentStatusApiModel,
  TrialApiModel,
} from './apiModels';
import { defaultFreeSubscription, pristineSubscription } from './constants';
import { currencyToSymbol } from './utils';

export function getSubscriptionInfoService(
  subscription: SubscriptionApiModel | null,
  trials: Trial | null
): SubscriptionInfoData {
  return transformSubscriptionForFe(subscription, trials);
}

type SubscriptionProductTemp = PaidSubscriptionStatus | null;
type TrialProductTemp = TrialApiModel | null;

/**
 * Transform response so that it returns the most advantageous
 * subscription to the user.
 */
export function transformSubscriptionForFe(
  subscriptionResp: SubscriptionApiModel | null,
  trialResp: TrialApiModel | null
): SubscriptionInfoData {
  return {
    products: {
      plus: transformSubscriptionProductByName(
        SubscriptionProductName.plus,
        subscriptionResp,
        trialResp
      ),
      rooms: transformSubscriptionProductByName(
        SubscriptionProductName.rooms,
        subscriptionResp,
        trialResp
      ),
      edu: transformSubscriptionProductByName(
        SubscriptionProductName.edu,
        subscriptionResp,
        trialResp
      ),
    },
    ...transformSubscriptionInfo(subscriptionResp, trialResp),
  };
}

/**
 * Transform subscription info to match SubscriptionInfo shape.
 */
function transformSubscriptionInfo(
  subscriptionResponse: SubscriptionApiModel | null,
  trialResponse: TrialApiModel | null
): {
  subscriptionInfo: SubscriptionInfo | null;
  hasExistingSubscription: boolean;
  isActiveSubscription: boolean;
  isActiveTrial: boolean;
  isCanceledSubscription: boolean;
} {
  const getSubscriptionInfo = (): SubscriptionInfo | null => {
    if (!subscriptionResponse) {
      return null;
    }

    const { type: paymentType, ...rest } = subscriptionResponse;

    return {
      paymentType,
      ...rest,
    };
  };

  const subscriptionInfo = getSubscriptionInfo();

  return {
    subscriptionInfo,
    hasExistingSubscription: !!subscriptionInfo,
    isActiveSubscription:
      subscriptionInfo?.status === SubscriptionCurrentStatusApiModel.Active ||
      subscriptionInfo?.status === SubscriptionCurrentStatusApiModel.PastDue,
    isActiveTrial: !!trialResponse?.isActive,
    isCanceledSubscription: subscriptionInfo?.status === SubscriptionCurrentStatusApiModel.Canceled,
  };
}

/**
 * Transform the subscription product based on 'SubscriptionProductName' type.
 */
function transformSubscriptionProductByName(
  productName: SubscriptionProductName,
  subscriptionResp: SubscriptionApiModel | null,
  trialsResp: TrialApiModel | null
): SubscriptionStatus {
  const getSubscriptionProductFromResp = (): SubscriptionProductTemp => {
    const productFullName = SubscriptionProductFullName[productName];
    const productShortName = SubscriptionProductShortName[productName];
    const product = subscriptionResp?.products.find(product => product.name === productName);
    if (!subscriptionResp || !product) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { products, type: paymentType, ...rest } = subscriptionResp;
    return {
      ...rest,
      ...product,
      // Stripe represents amount with 2 decimals,
      // for example, 20$ gets represented as 2000 unitAmount
      unitAmount: product.unitAmount / 100,
      paymentType,
      fullName: productFullName,
      shortName: productShortName,
      // This is a temp statusType, we compute the actual status
      // in the getMostAdvantageousSubscriptionProductType function
      statusType: SubscriptionStatusType.PAID,
      currencySymbol: currencyToSymbol(rest.currency),
    };
  };

  return getMostAdvantageousSubscriptionProduct(
    getSubscriptionProductFromResp(),
    trialsResp,
    productName
  );
}

/**
 * Get the data for the most advantageous product.
 */
function getMostAdvantageousSubscriptionProduct(
  subscription: SubscriptionProductTemp,
  trial: TrialProductTemp,
  productName: SubscriptionProductName
): SubscriptionStatus {
  const freeSubscription = {
    ...defaultFreeSubscription,
  };

  switch (getMostAdvantageousSubscriptionProductType(subscription, trial)) {
    case SubscriptionStatusType.PRISTINE: {
      return pristineSubscription;
    }
    case SubscriptionStatusType.FREE: {
      return {
        ...freeSubscription,
        expirationDate:
          (checkIsTrialInactive(trial) ? trial?.stop : subscription?.expirationDate) ||
          freeSubscription.expirationDate,
        wasTrial: checkIsTrialInactive(trial),
      };
    }
    case SubscriptionStatusType.TRIAL: {
      if (!trial) {
        return freeSubscription;
      }
      return {
        statusType: SubscriptionStatusType.TRIAL,
        name: productName,
        startDate: trial.start,
        expirationDate: trial.stop,
        trialDaysLeft: differenceInDays(new Date(trial.stop || Date.now()), new Date()),
      };
    }
    case SubscriptionStatusType.PAID: {
      return subscription ?? freeSubscription;
    }
  }
}

/**
 * Get the most advantageous subscriptions type, check the explanatory diagram on:
 * https://gitlab.com/airtame/cloud/frontend/-/blob/master/docs/subscriptions-product-logic.png
 */
function getMostAdvantageousSubscriptionProductType(
  subscription: SubscriptionProductTemp,
  trial: TrialProductTemp
): SubscriptionStatusType {
  if (checkIsPristine(subscription, trial)) {
    return SubscriptionStatusType.PRISTINE;
  } else if (checkIsPaid(subscription)) {
    return SubscriptionStatusType.PAID;
  } else if (checkIsTrial(trial)) {
    return SubscriptionStatusType.TRIAL;
  } else if (checkIsFree(subscription, trial)) {
    return SubscriptionStatusType.FREE;
  } else {
    // If we cannot detect the subscription for whatever reason
    // we return the FREE type
    return SubscriptionStatusType.FREE;
  }
}

function checkIsPristine(subscription: SubscriptionProductTemp, trial: TrialProductTemp): boolean {
  return !subscription && !trial;
}

function checkIsFree(subscription: SubscriptionProductTemp, trial: TrialProductTemp): boolean {
  if (!subscription) {
    return checkIsTrialInactive(trial);
  } else {
    return checkIsPaidInactive(subscription) && (!trial || checkIsTrialInactive(trial));
  }
}

function checkIsPaid(subscription: SubscriptionProductTemp): boolean {
  return (
    subscription?.status === SubscriptionCurrentStatus.Active ||
    subscription?.status === SubscriptionCurrentStatus.PastDue
  );
}

function checkIsPaidInactive(subscription: SubscriptionProductTemp): boolean {
  return subscription?.status === SubscriptionCurrentStatus.Inactive;
}

function checkIsTrial(trial: TrialProductTemp) {
  return !!trial?.isActive;
}

function checkIsTrialInactive(trial: TrialProductTemp): boolean {
  return !!(trial && !trial?.isActive);
}
