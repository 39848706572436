import React from 'react';
import { Logo } from '@airtame/ui';
import classnames from 'classnames';
import { block } from './constants';

type LayoutHeaderLogoProps = React.HTMLAttributes<HTMLDivElement>;

export const LayoutHeaderLogo = ({
  className,
  onClick,
  ...props
}: LayoutHeaderLogoProps): React.ReactElement => {
  const wrapperClassName = classnames(className, `${block}__header-logo`, {
    [`${block}__header-logo--link`]: !!onClick,
  });

  return (
    <div className={wrapperClassName} {...props}>
      <div onClick={onClick}>
        <Logo className={`${block}__header-logo-svg`} />
      </div>
    </div>
  );
};
