import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getSubscriptionInfoService } from 'src/services/subscriptions/getSubscriptionInfoService';
import { SubscriptionInfoData } from 'src/typings/Subscription';
import { useGetSubscriptionSuspense } from './useGetSubscriptionSuspense';
import { useGetTrialSuspense } from './useGetTrialSuspense';
import { getSubscriptionInfoKey } from './utils';

export function useGetSubscriptionInfoSuspense(): void {
  const queryClient = useQueryClient();
  const { data: trials } = useGetTrialSuspense();
  const { data: subscription } = useGetSubscriptionSuspense();

  useEffect(() => {
    queryClient.setQueryData<SubscriptionInfoData>(getSubscriptionInfoKey(), () =>
      getSubscriptionInfoService(subscription, trials)
    );
  }, [subscription, trials]);
}
