import { airtameAddUrl, airtameComUrl } from 'src/constants/url';

/**
 * Users that come from 'airtameAddUrl' are redirected to:
 * - /devices/setup if logged in
 * - /signup/welcome if logged out
 */
export function isUserFromAirtameAddUrl(): boolean {
  const referrerUrl = document.referrer === '' ? airtameComUrl : document.referrer;
  const referrerURL = new URL(referrerUrl);
  const airtameAddURL = new URL(airtameAddUrl);

  return referrerURL.host === airtameAddURL.host && referrerURL.pathname === airtameAddURL.pathname;
}
