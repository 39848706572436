import {
  Auth400ApiError,
  Auth401ApiError,
  UserApiModel,
  UserGroupAccessTypeApiModel,
  UserPermissionsApiModel,
  UserRoleApiModel,
  UserSettingsApiModel,
} from 'src/services/user/apiModels';
import { CamelCase } from 'src/typings/global';

export type { UserSettingsApiModel as UserSettings };
export type { UserGroupAccessTypeApiModel as UserGroupAccessType };

export type User = Omit<UserApiModel, 'permissions'> & {
  permissions: UserPermission[];
};

export type UserRole = UserRoleApiModel;

export const UserRole: { [K in UserRoleApiModel]: K } = {
  owner: 'owner',
  administrator: 'administrator',
  // In the FE 'Device and content manager'
  manager: 'manager',
  // In the FE 'Content manager'
  user: 'user',
  // In the FE 'Moderator'
  moderator: 'moderator',
} as const;

export type UserPermission =
  | UserPermissionsApiModel
  // Permissions prefixed with `Fe` do not exist in the BE, they are only present in the FE,
  // effectively these permissions only apply to the `owner` user
  // which has access to all permissions
  | 'fe-can-change-org-owner'
  | 'fe-can-edit-org-name'
  | 'fe-can-remove-organization'
  | 'fe-can-buy-subscriptions';

type UserPermissionsType = {
  // in the FE `homescreen` mean multiple things, so we override these permissions for `screen`
  [K in Exclude<
    UserPermission,
    'can-get-homescreens' | 'can-enable-disable-homescreen' | 'can-change-homescreen-settings'
  > as Capitalize<CamelCase<K, '-'>>]: K;
} & {
  CanGetScreens: 'can-get-homescreens';
  CanEnableDisableScreen: 'can-enable-disable-homescreen';
  CanChangeScreenSettings: 'can-change-homescreen-settings';
};

export const UserPermissions: UserPermissionsType = {
  CanAddDevice: 'can-add-device',
  CanAddDeviceToGroup: 'can-add-device-to-group',
  CanChangeAlertNotification: 'can-change-alert-notification',
  CanChangeDeviceSettings: 'can-change-device-settings',
  CanChangeScreenSettings: 'can-change-homescreen-settings',
  CanChangeUserSettings: 'can-change-user-settings',
  CanCreateGroup: 'can-create-group',
  CanDeleteDevice: 'can-delete-device',
  CanDeleteGroup: 'can-delete-group',
  CanEnableDisableScreen: 'can-enable-disable-homescreen',
  CanGetDevices: 'can-get-devices',
  CanGetScreens: 'can-get-homescreens',
  CanInviteUser: 'can-invite-user',
  CanRebootDevice: 'can-reboot-device',
  CanRemoveOrgMember: 'can-remove-org-member',
  CanRenameGroup: 'can-rename-group',
  CanRequestPasswordResetLinks: 'can-request-password-reset-links',
  CanStopStream: 'can-stop-stream',
  CanUpdateDevice: 'can-update-device',
  CanUpdateDeviceSubscriptions: 'can-update-device-subscriptions',
  CanActivateVouchers: 'can-activate-vouchers',
  CanUpdateBilling: 'can-update-billing',
  FeCanBuySubscriptions: 'fe-can-buy-subscriptions',
  FeCanChangeOrgOwner: 'fe-can-change-org-owner',
  FeCanEditOrgName: 'fe-can-edit-org-name',
  FeCanRemoveOrganization: 'fe-can-remove-organization',
} as const;

export type Auth400Error = Auth400ApiError;

export type Auth401Error = Auth401ApiError;
