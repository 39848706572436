import { authApiBaseUrl } from 'src/constants/api';
import * as http from 'src/constants/http';
import { Auth400Error } from 'src/typings/User';
import { defaultHeaders } from './constants';
import { FetchBaseOptions } from './types';

export function isAuthUrl(url: string): boolean {
  return url.includes(authApiBaseUrl);
}

export function getFetchOptions({
  method = http.GET,
  body,
  customHeaders,
}: FetchBaseOptions): RequestInit {
  return Object.assign(
    {},
    {
      method,
      headers: new Headers({ ...defaultHeaders, ...customHeaders }),
      credentials: http.sameOrigin,
    },
    { body }
  );
}

/**
 * All error responses from Cloud API calls are of the form { message: errorText }
 * and some of them may contain additional information, stored in content.
 */
export function getCloudApiErrorData(errorText: string): {
  message: string;
  data: unknown;
} {
  try {
    const data: ReturnType<typeof getCloudApiErrorData> = JSON.parse(errorText);
    return {
      message: data.message,
      data,
    };
  } catch (err) {
    return {
      message: '',
      data: errorText,
    };
  }
}

/**
 * Error responses from ALlAuth API calls are of the form:
 * - Auth400Error
 * - Auth401Error
 * @see {@link https://docs.allauth.org/en/latest/headless/openapi-specification/}
 */
export function getAuthApiErrorData(
  status: number,
  errorText: string
): {
  message: string;
  data: unknown;
} {
  try {
    switch (status) {
      case 401:
      case 410: {
        return {
          message: 'The user is not authenticated',
          data: errorText,
        };
      }
      default: {
        const data: Auth400Error = JSON.parse(errorText);
        return {
          message: data.errors[0]?.message ?? '',
          data,
        };
      }
    }
  } catch (error) {
    return {
      message: '',
      data: errorText,
    };
  }
}
