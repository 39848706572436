const ns = 'subscriptions';

export const subscriptionsKeys = {
  voucher: `${ns}-voucher`,
  billingHistory: `${ns}-billing-history`,
  paymentMethod: `${ns}-payment-method`,
  trial: `${ns}-trial`,
  subscription: `${ns}-subscription`,
  subscriptionInfo: `${ns}-subscription-info`,
};
