import { SignupProgressTypes } from 'src/components/routes/Signup/SignupSteps/SignupProgress/types';

const ns = 'signup';

export const signup = {
  welcomePage: `${ns}__welcome-page`,
  welcomeLogoAction: `${ns}__welcome-logo-action`,
  welcomeGoToSignupUserAction: `${ns}__welcome-go-to-signup-user-action`,
  welcomeAlreadyHasAccountAction: `${ns}__welcome-already-has-account-action`,
  stepsLogoAction: `${ns}__steps-logo-action`,
  stepsCancelAction: `${ns}__steps-cancel-action`,
  stepsProgress: `${ns}__steps-progress`,
  stepsProgressItem: (itemName: SignupProgressTypes): string =>
    `${ns}__steps-progress-item-${itemName}`,
  userStepPage: `${ns}__user-step-page`,
  userStepGoogleProvider: `${ns}__user-step-google-provider`,
  userStepMicrosoftProvider: `${ns}__user-step-microsoft-provider`,
  userStepForm: `${ns}__user-step-form`,
  userStepContinueAction: `${ns}__user-step-continue-action`,
  orgStepPage: `${ns}__org-step-page`,
  orgStepForm: `${ns}__org-step-form`,
  orgStepContinueAction: `${ns}__org-step-continue-action`,
  cancelSignupModal: `${ns}__cancel-signup-modal`,
  cancelSignupModalContinueAction: `${ns}__cancel-signup-modal-continue-action`,
  cancelSignupModalCancelAction: `${ns}__cancel-signup-modal-cancel-action`,
  invitationPage: `${ns}__invitation-page`,
  invitationLogoAction: `${ns}__invitation-logo-action`,
  invitationForm: `${ns}__invitation-form`,
  invitationSignupAction: `${ns}__invitation-signup-action`,
  invitationGoogleProvider: `${ns}__invitation-google-provider`,
  invitationMicrosoftProvider: `${ns}__invitation-microsoft-provider`,
};
