import ExtendableError from 'es6-error';
import * as errorMessages from '../constants/errorMessages';

/**
 * Custom error type so we can differentiate API errors (which we'd like to render)
 * from internal errors.
 */
export class APIError extends ExtendableError {
  public status: number;
  public data: unknown;

  constructor(status: number, message: string, additionalContent: unknown) {
    super(message);
    this.status = status;
    this.data = additionalContent;
  }
}

/**
 * Custom error type so we can differentiate Validation errors (which we'd like to render)
 * from internal errors.
 */
export class ValidationError extends ExtendableError {}

type ErrorType = Error | APIError | ValidationError;

/**
 * Provides an error message.
 */
export function getErrorMessage(err: ErrorType): string {
  if ((err instanceof APIError || err instanceof ValidationError) && err.message) {
    return err.message;
  }

  return errorMessages.somethingWentWrong;
}
