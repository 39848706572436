import React, { useCallback, useMemo } from 'react';
import { useToastContext } from '@airtame/ui';

type ToastProps = Parameters<ReturnType<typeof useToastContext>[0]>[0];
type ToastOptions = Omit<ToastProps, 'children' | 'role'>;

type UseNotification = {
  errorNotification: (message: string, options?: ToastOptions) => void;
  successNotification: (message: string, options?: ToastOptions) => void;
  infoNotification: (message: string, options?: ToastOptions) => void;
};

export function useNotification(): UseNotification {
  const [addToast] = useToastContext();

  const displayNotification = useCallback(
    (message: string, options: ToastOptions = {}) => {
      const { ...rest } = options;

      addToast({
        children: <div data-variant={rest.variant}>{message}</div>,
        role: 'alert',
        ...rest,
      });
    },
    [addToast]
  );

  const errorNotification = useCallback(
    (message: string, options: ToastOptions = {}) => {
      displayNotification(message, {
        ...options,
        variant: 'error',
      });
    },
    [displayNotification]
  );

  const successNotification = useCallback(
    (message: string, options: ToastOptions = {}) => {
      displayNotification(message, {
        ...options,
        variant: 'success',
      });
    },
    [displayNotification]
  );

  const infoNotification = useCallback(
    (message: string, options: ToastOptions = {}) => {
      displayNotification(message, {
        ...options,
        variant: 'informative',
      });
    },
    [displayNotification]
  );

  return useMemo(
    () => ({ errorNotification, successNotification, infoNotification }),
    [errorNotification, successNotification, infoNotification]
  );
}
