import React, { useEffect } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { useNotification } from 'src/hooks/useNotification';
import { queryClient } from 'src/queries/queryClient';

export const QueryProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const { errorNotification } = useNotification();

  useEffect(() => {
    const queryCache = queryClient.getQueryCache();

    queryCache.config.onError = (_error, query) => {
      const errorMessage = query?.meta?.errorMessage;

      if (errorMessage && typeof errorMessage === 'string') {
        errorNotification(errorMessage);
      }
    };
  }, []);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
