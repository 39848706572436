import { createZustandStore } from 'src/utils/zustand';
import { MainLayoutStoreState } from './types';

export const { useStore: useMainLayoutStore, createAction } =
  createZustandStore<MainLayoutStoreState>('MainLayoutStore', {
    showSideNav: false,
  });

export function setShowSideNav(showSideNav: boolean): void {
  const setShowSideNavAction = createAction('setShowSideNav', {
    showSideNav,
  });

  setShowSideNavAction();
}
