import { getDeviceOverviewKey } from 'src/queries/device/utils';
import { getOrganizationKey } from 'src/queries/organization/utils';
import { queryClient } from 'src/queries/queryClient';
import { subscriptionsKeys } from './constants';

export function getVoucherKey(voucherCode = ''): string[] {
  if (voucherCode) {
    return [subscriptionsKeys.voucher, voucherCode];
  }

  return [subscriptionsKeys.voucher];
}

export function getBillingHistoryKey(): string[] {
  return [subscriptionsKeys.billingHistory];
}

export function getPaymentMethodKey(): string[] {
  return [subscriptionsKeys.paymentMethod];
}

export function getTrialKey(): string[] {
  return [subscriptionsKeys.trial];
}

export function getSubscriptionKey(): string[] {
  return [subscriptionsKeys.subscription];
}

export function getSubscriptionInfoKey(): string[] {
  return [subscriptionsKeys.subscriptionInfo];
}

export async function clearSubscriptionInfo(): Promise<void> {
  // Order here is important! Since subscriptions info calculations rely on subscription and trials,
  // we MUST invalidate them before the other dependent queries
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: getTrialKey() }),
    queryClient.invalidateQueries({ queryKey: getSubscriptionKey() }),
  ]);
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: getOrganizationKey() }),
    queryClient.invalidateQueries({ queryKey: getDeviceOverviewKey() }),
  ]);
}
