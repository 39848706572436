import { queryClient } from 'src/queries/queryClient';
import { postLogoutUserService } from 'src/services/user/postLogoutUserService';
import { resetAuthStore } from 'src/store/authStore';
import { AnalyticsEvents, googleAnalytics } from '../googleAnalytics';

/**
 * Utility function to logout the user outside of React.
 */
export function logoutUser(): void {
  queryClient
    .getMutationCache()
    .build(queryClient, {
      mutationFn: () => postLogoutUserService(),
      onSuccess: () => {
        resetAuthStore();

        googleAnalytics.track(AnalyticsEvents.LOGOUT);
        googleAnalytics.identify('');
      },
    })
    .execute({});
}
