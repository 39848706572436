import { useEffect, useRef } from 'react';

/**
 * Custom hook mostly used to replicate prevProp.
 */
export const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current as T;
};
