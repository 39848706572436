import { createZustandStore } from 'src/utils/zustand';
import { googleIdentityStoreName } from './constants';
import { GoogleIdentityStoreState } from './types';

export const { useStore: useGoogleIdentityStore, createAction } =
  createZustandStore<GoogleIdentityStoreState>(googleIdentityStoreName, {
    idToken: '',
    isScriptAppended: false,
  });

export const setIsScriptAppended = (): void => {
  const setIsScriptAppendedAction = createAction('setIsScriptAppended', {
    isScriptAppended: true,
  });

  setIsScriptAppendedAction();
};

export const setIdToken = (newToken: string): void => {
  const setIdTokenAction = createAction('setIdToken', {
    idToken: newToken,
  });

  setIdTokenAction();
};
