import { useSuspenseQuery, UseSuspenseQueryResult } from '@tanstack/react-query';
import { getTrialService } from 'src/services/subscriptions/getTrialService';
import { Trial } from 'src/typings/Subscription';
import { APIError } from 'src/utils/error';
import { getTrialKey } from './utils';

export function useGetTrialSuspense(): UseSuspenseQueryResult<Trial | null, APIError> {
  return useSuspenseQuery({
    queryKey: getTrialKey(),
    queryFn: getTrialService,
  });
}
