import React from 'react';
import { Theme, ToastContextProvider } from '@airtame/ui';
import { ErrorBoundary, withProfiler } from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Router } from 'react-router';
import { MicrosoftAuthContextProvider } from 'src/components/routes/shared/MicrosoftAuth';
import { rootHistory } from 'src/utils/history';
import { BootstrapData } from './BootstrapData';
import { QueryProvider } from './QueryProvider';
import { RootError } from './RootError';
import { Routes } from './Routes';

const RootComponent = (): React.ReactElement => {
  return (
    <Theme theme="dark">
      <ErrorBoundary fallback={<RootError />}>
        <ToastContextProvider defaultSettings={{ autoClose: 5000, hasIcon: true }}>
          <QueryProvider>
            <MicrosoftAuthContextProvider>
              <BootstrapData>
                <Router history={rootHistory}>
                  <Routes />
                </Router>
              </BootstrapData>
            </MicrosoftAuthContextProvider>
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
          </QueryProvider>
        </ToastContextProvider>
      </ErrorBoundary>
    </Theme>
  );
};

export const Root = withProfiler(RootComponent);
