import React from 'react';
import classnames from 'classnames';
import { block } from './constants';

type LayoutBodyProps = React.HTMLAttributes<HTMLDivElement>;

export const LayoutBody = ({
  children,
  className,
  ...props
}: LayoutBodyProps): React.ReactElement => {
  const wrapperClassName = classnames(className, `${block}__body`);

  return (
    <main className={wrapperClassName} {...props}>
      {children}
    </main>
  );
};
