import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { setShowSideNav } from 'src/components/routes/root/MainLayout/mainLayoutStore';
import * as routes from 'src/constants/routes';
import { useAuthStore } from 'src/store/authStore';
import { Redirect } from './Redirect';

export type NoAuthRouteProps = {
  component: React.ComponentType<unknown>;
} & RouteProps;

/**
 * NoAuth route can only be accessed if the user is not authenticated.
 */
export const NoAuthRoute = ({
  component: Component,
  ...rest
}: NoAuthRouteProps): React.ReactElement => {
  const userIsAuthenticated = useAuthStore(state => state.isAuthenticated);

  useEffect(() => {
    setShowSideNav(false);
  }, []);

  if (userIsAuthenticated) {
    return <Redirect to={routes.devices} />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};
