import React from 'react';
import classnames from 'classnames';
import { block } from './constants';

type LayoutBodyContentProps = React.HTMLAttributes<HTMLDivElement>;

export const LayoutBodyContent = ({
  children,
  className,
  ...props
}: LayoutBodyContentProps): React.ReactElement => {
  const wrapperClassName = classnames(className, `${block}__body-content`);

  return (
    <div className={wrapperClassName} {...props}>
      {children}
    </div>
  );
};
