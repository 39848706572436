import React from 'react';
import {
  Layout,
  LayoutBody,
  LayoutBodyContent,
  LayoutHeader,
  LayoutHeaderLogo,
} from 'src/components/ui/Layout';
import { block } from './constants';

export const RootError = (): React.ReactElement => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLogo />
      </LayoutHeader>
      <LayoutBody>
        <LayoutBodyContent className={`${block}__body-content`}>
          <div className={`${block}__text typography`}>
            An error has occurred, please try to refresh the page.
          </div>
        </LayoutBodyContent>
      </LayoutBody>
    </Layout>
  );
};
