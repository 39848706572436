import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';

type RedirectProps = {
  from?: string;
  to: string;
};

/**
 * Declarative redirect.
 *
 * Note: React Router DOM v5 doesn't work well with the built in
 * <Redirect /> and the history.replace functionality so it's best
 * to use only the history, and create an abstraction over it.
 */
export const Redirect = ({ from, to, ...rest }: RedirectProps & RouteProps): React.ReactElement => {
  return <Route {...rest} path={from} render={() => <RedirectUtility from={from} to={to} />} />;
};

const RedirectUtility = ({ from, to }: RedirectProps) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!from || from === '*' || location.pathname === from) {
      history.replace(to);
    }
  }, [history, location.pathname, from, to]);

  return null;
};
