const ns = 'login';

export const login = {
  page: `${ns}__page`,
  loginAction: `${ns}__login-action`,
  noAccountAction: `${ns}__no-account-action`,
  forgotPasswordAction: `${ns}__forgot-password-action`,
  goToSignupAction: `${ns}__go-to-signup-action`,
  noAccountModal: `${ns}__no-account-modal`,
  noAccountModalCloseAction: `${ns}__no-account-modal-close-action`,
  forgotPasswordModal: `${ns}__forgot-password-modal`,
  forgotPasswordSendEmailAction: `${ns}__forgot-password-send-email-action`,
  forgotPasswordCloseAction: `${ns}__forgot-password-close-action`,
};
