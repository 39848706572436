import { useSuspenseQuery, UseSuspenseQueryResult } from '@tanstack/react-query';
import { getSubscriptionService } from 'src/services/subscriptions/getSubscriptionService';
import { Subscription } from 'src/typings/Subscription';
import { APIError } from 'src/utils/error';
import { getSubscriptionKey } from './utils';

export function useGetSubscriptionSuspense(): UseSuspenseQueryResult<
  Subscription | null,
  APIError
> {
  return useSuspenseQuery({
    queryKey: getSubscriptionKey(),
    queryFn: getSubscriptionService,
  });
}
